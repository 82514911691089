import React, { useMemo, useRef, useState, useCallback } from "react";
import {
  Box,
  Button,
  Center,
  Divider,
  Heading,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SlideFade,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import { MdMoreHoriz } from "react-icons/md";
import { Breadcrumb, ExportCsv, Paginator, PermissionedContainer, Portal, TableDrawer, TableEmpty } from "components";
import { useFetchData, useCacheState, useDocumentTitle, usePermissioned, useTable } from "hooks";
import { Content, ContentBody, ContentHeader } from "pages/Private/Container";
import { TbFileExport } from "react-icons/tb";
import defaultColumns from "./defaultColumns";
import Filters from "./filters";
import { BiSupport } from "react-icons/bi";

export const ConsumerUnitsList = () => {
  useDocumentTitle("Unidades consumidoras");
  const location = useLocation();
  const navigate = useNavigate();
  const [query, setQuery] = useState();
  const [sort, setSort] = useCacheState(useMemo(() => ({ key: "ConsumerUnitsList.sort", defaultValue: { createdAt: -1 } }), []));
  const [page, setPage] = useCacheState(useMemo(() => ({ key: "ConsumerUnitsList.page", defaultValue: 0 }), []));
  const [perPage, setPerPage] = useCacheState(useMemo(() => ({ key: "ConsumerUnitsList.perPage", defaultValue: 100 }), []));
  const [data, isLoadingData, refreshData, errorData, timestampData, fetchAllPages] = useFetchData(
    useMemo(
      () => ({
        path: "/private/consumer-units",
        params: { query, sort, page, perPage },
        options: { isEnabled: _.isObject(query) },
      }),
      [query, sort, page, perPage]
    )
  );
  const { columns, cells, updateTableColumns } = useTable(
    useMemo(() => ({ id: location.pathname, defaultColumns, _v: 21 }), [location.pathname])
  );
  const { isOpen: isOpenExportData, onOpen: onOpenExportData, onClose: onCloseExportData } = useDisclosure();
  const isAllowedExportSensitiveData = usePermissioned("customers.exportSensitiveData.*");
  const isAllowedReadSensitiveData = usePermissioned("customers.readSensitiveData.*");
  const tableDrawerRef = useRef();

  const handleTableDrawerChange = useCallback(
    ({ sort, perPage, columns }) => {
      setSort(sort);
      setPerPage(perPage);
      updateTableColumns(columns);
    },
    [updateTableColumns]
  );

  return (
    <>
      <Content>
        <ContentHeader>
          <HStack justify="space-between">
            <Breadcrumb items={[{ label: "cadastros" }, { to: "/consumer-units", label: "unidades consumidoras" }]} />
            <SlideFade in={true} offsetY="-20px">
              <HStack>
                <PermissionedContainer required={["customers.export"]}>
                  <Box>
                    <Menu>
                      <MenuButton as={Button} size="sm" variant="outline" rightIcon={<Icon as={MdMoreHoriz} />}>
                        mais ações
                      </MenuButton>
                      <Portal>
                        <MenuList fontSize="sm">
                          <PermissionedContainer required="customers.export">
                            <MenuItem onClick={onOpenExportData}>
                              <HStack>
                                <Icon as={TbFileExport} />
                                <Text>exportar unidades consumidoras</Text>
                              </HStack>
                            </MenuItem>
                          </PermissionedContainer>
                        </MenuList>
                      </Portal>
                    </Menu>
                  </Box>
                </PermissionedContainer>
              </HStack>
            </SlideFade>
          </HStack>
          <Heading my="15px" size="md">
            Unidades consumidoras
          </Heading>
          <Filters
            onQuery={setQuery}
            onRefresh={refreshData}
            onPage={setPage}
            isLoading={isLoadingData}
            onTableDrawer={() => tableDrawerRef.current.open()}
          />
        </ContentHeader>

        <ContentBody>
          <Table size="sm" whiteSpace="nowrap">
            <Thead>
              <Tr>
                <Th>#</Th>
                {cells.map(({ accessor, title }) => (
                  <Th key={accessor}>{title}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {_.map(data?.data, (item) => (
                <Tr
                  key={item._id}
                  cursor="pointer"
                  _hover={{ _light: { bg: "gray.50" }, _dark: { bg: "gray.700" } }}
                  _active={{ _light: { bg: "gray.100" }, _dark: { bg: "gray.900" } }}
                  onDoubleClick={() => navigate(`/customers/edit/${item.customer._id}#consumer-units`)}
                >
                  <Td>
                    {item.sentToCollectionAgency && (
                      <Tooltip label="Cobrança terceirizada">
                        <Center bg="purple.500" w="25px" h="25px" borderRadius="full">
                          <Icon as={BiSupport} color="white" />
                        </Center>
                      </Tooltip>
                    )}
                  </Td>
                  {cells.map(({ accessor, formatter, render }) => (
                    <Td key={accessor}>{formatter?.(item) ?? render?.(item, { isAllowedReadSensitiveData })}</Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
            <Tfoot _light={{ bg: "gray.50" }} _dark={{ bg: "gray.900" }} fontWeight="bold">
              <Tr>
                <Td></Td>
                {cells.map(({ accessor, renderFoot }) => (
                  <Td key={accessor}>{renderFoot?.(data?.footer ?? {})}</Td>
                ))}
              </Tr>
            </Tfoot>
          </Table>
          {isLoadingData && (
            <Center p="30px">
              <Spinner />
            </Center>
          )}
          <TableEmpty isLoading={isLoadingData} size={_.size(data?.data)} />
        </ContentBody>
      </Content>

      <Divider />

      <SlideFade in={true} offsetY="20px">
        <Box p="20px">
          <Paginator loading={isLoadingData} page={page} size={data?.size} perPage={perPage} onPaginate={setPage} />
        </Box>
      </SlideFade>

      <TableDrawer
        ref={tableDrawerRef}
        defaultColumns={defaultColumns}
        columns={columns}
        sort={sort}
        perPage={perPage}
        onChange={handleTableDrawerChange}
      />

      <ExportCsv
        filename="unidades_consumidoras"
        onFetchData={fetchAllPages}
        columns={columns}
        isOpen={isOpenExportData}
        onClose={onCloseExportData}
        isAllowedExportSensitiveData={isAllowedExportSensitiveData}
      />
    </>
  );
};
