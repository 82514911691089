import React, { useContext, useEffect, useState, useCallback, useMemo, Fragment } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import InputMask from "react-input-mask";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import { api, yup, EventEmitter, currency } from "lib";
import { useCustomToast } from "hooks";
import { messages } from "consts";
import InvoicesDetailsContext from "./context";
import { MonetaryCorrections } from "containers/ChargeUpdate/dialog";

const ChargeCreate = ({ isOpen, onClose }) => {
  const { _id } = useParams();
  const { formData: invoiceFormData, refreshData } = useContext(InvoicesDetailsContext);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const receivableAmount = useMemo(() => {
    if (invoiceFormData.isUnified)
      return (
        invoiceFormData.amount +
        (invoiceFormData.distributor?.current.amount || 0) -
        (invoiceFormData.consumerUnit?.traderAvailableCreditBalance || 0) -
        (invoiceFormData.consumerUnit?.distributorAvailableCreditBalance || 0)
      );
    return invoiceFormData.amount - (invoiceFormData.consumerUnit?.traderAvailableCreditBalance || 0);
  }, [invoiceFormData.amount, invoiceFormData.distributor, invoiceFormData.isUnified, invoiceFormData.consumerUnit]);
  const toast = useCustomToast();

  useEffect(() => {
    setFormData((state) => ({ ...state, dueDate: invoiceFormData.dueDate }));
    setFormErrors({});
  }, [isOpen, invoiceFormData.dueDate]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        await api.put(`/private/invoices/${_id}/charges`, data);
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        EventEmitter.emit("charges.refresh");
        refreshData();
        onClose();
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, refreshData, toast]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        dueDate: yup
          .date()
          .typeError(messages.error.invalidDate)
          .required(messages.error.required)
          .min(moment().endOf("day").toDate(), messages.error.minDueDate),
      });
      const data = {
        ...formData,
        invoiceId: _id,
        dueDate: moment(formData.dueDate, "DD/MM/YYYY").endOf("day").toDate(),
      };
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = {};
      for (const { path, message } of error.inner) _.set(formErrors, path, message);
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  return (
    <AlertDialog isOpen={isOpen} onClose={onClose} isCentered>
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>Emitir cobrança</AlertDialogHeader>
        <AlertDialogBody>
          <Text>Deseja realmente emitir uma cobrança para a fatura atual?</Text>
          <Grid templateColumns="repeat(12, 1fr)" gap={2}>
            <GridItem colSpan={12}>
              <FormControl isRequired={true} isInvalid={formErrors.dueDate}>
                <FormLabel fontSize="sm">Vencimento</FormLabel>
                <Input
                  as={InputMask}
                  mask="99/99/9999"
                  value={formData.dueDate ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, dueDate: target.value }))}
                />
                <FormErrorMessage>{formErrors.dueDate}</FormErrorMessage>
              </FormControl>
            </GridItem>

            <GridItem
              colSpan={{ base: 12, lg: 4 }}
              as={VStack}
              alignItems="center"
              justifyContent="center"
              spacing={0}
              borderWidth="1px"
              borderRadius="lg"
              p="10px"
              textAlign="center"
            >
              <Text fontSize="xs">Valor CEMIG</Text>
              <Text fontSize="sm" fontWeight="semibold" textDecoration={invoiceFormData.isUnified ? "none" : "line-through"}>
                {currency(invoiceFormData.distributor?.current.amount || 0)}
              </Text>
              {invoiceFormData.consumerUnit?.distributorAvailableCreditBalance > 0 && (
                <Text fontSize="xs" fontWeight="bold" color="green.500">
                  -{currency(invoiceFormData.consumerUnit?.distributorAvailableCreditBalance || 0)}
                </Text>
              )}
            </GridItem>
            <GridItem
              colSpan={{ base: 12, lg: 4 }}
              as={VStack}
              alignItems="center"
              justifyContent="center"
              spacing={0}
              borderWidth="1px"
              borderRadius="lg"
              p="10px"
              textAlign="center"
            >
              <Text fontSize="xs">Valor Click</Text>
              <Text fontSize="sm" fontWeight="semibold">
                {currency(invoiceFormData.amount)}
              </Text>
              {invoiceFormData.consumerUnit?.traderAvailableCreditBalance > 0 && (
                <Text fontSize="xs" fontWeight="bold" color="green.500">
                  -{currency(invoiceFormData.consumerUnit?.traderAvailableCreditBalance || 0)}
                </Text>
              )}
            </GridItem>
            <GridItem
              colSpan={{ base: 12, lg: 4 }}
              as={VStack}
              alignItems="center"
              justifyContent="center"
              spacing={0}
              borderWidth="1px"
              borderRadius="lg"
              p="10px"
              textAlign="center"
            >
              <Text fontSize="xs">Valor total</Text>
              <Text fontSize="sm" fontWeight="semibold">
                {currency(receivableAmount)}
              </Text>
            </GridItem>
          </Grid>

          {invoiceFormData.overdueDays >= 1 && (
            <Fragment>
              <Divider my={4} />
              <MonetaryCorrections {...{ formData, setFormData, receivableAmount, invoice: invoiceFormData }} />
            </Fragment>
          )}
        </AlertDialogBody>
        <AlertDialogFooter as={HStack} justify="flex-end">
          <Button size="sm" variant="outline" onClick={onClose}>
            cancelar
          </Button>
          <Button size="sm" colorScheme="main" isLoading={isLoadingSaveData} onClick={handleSubmit}>
            confirmar
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ChargeCreate;
