import _ from "lodash";

const groups = [
  {
    defaultIsOpen: true,
    key: "customers",
    label: "Clientes",
    items: [
      {
        key: "read",
        label: "Visualizar todos",
        description: "Caso o usuário não possua esta permissão ele poderá visualizar apenas os próprios cadastros.",
      },
      { key: "readOnlySentToCollectionAgency", label: "Visualizar apenas os enviados para cobrança terceirizada" },
      { key: "create", label: "Incluir" },
      {
        key: "update",
        label: "Alterar",
        items: [
          { key: "type", label: "Tipo" },
          { key: "name", label: "Nome/Razão social" },
          { key: "nickname", label: "Nome social/Nome fantasia" },
          { key: "document", label: "CPF/CNPJ" },
          { key: "rg", label: "RG" },
          { key: "ie", label: "Inscrição estadual" },
          { key: "cemigCustomerNumber", label: "Número do cliente CEMIG" },
          { key: "cemigEmail", label: "E-mail CEMIG Atende" },
          { key: "cemigPassword", label: "Senha CEMIG Atende" },
          { key: "cemigRegisterEmail", label: "E-mail para cadastro do CEMIG Atende" },
          { key: "cemigRegisterPassword", label: "Senha para cadastro do CEMIG Atende" },
          { key: "phone1", label: "Telefone 1" },
          { key: "phone2", label: "Telefone 2" },
          { key: "phone3", label: "Telefone 3" },
          { key: "address", label: "Endereço" },
          { key: "email", label: "E-mail" },
          { key: "gender", label: "Sexo" },
          { key: "birth", label: "Nascimento" },
          { key: "profession", label: "Profissão" },
          { key: "maritalStatus", label: "Estado civil" },
          { key: "naturalness", label: "Naturalidade" },
          { key: "earlyWarning", label: "Aviso prévio" },
          { key: "indicatedBy", label: "Indicado por" },
          { key: "exhibition", label: "Evento" },
          { key: "billingContacts", label: "Contatos para faturamento" },
          { key: "consultant", label: "Consultor" },
        ],
      },
      { key: "delete", label: "Excluir" },
      { key: "export", label: "Exportar" },
      { key: "verifyContact", label: "Verificar contato" },
      {
        key: "exportSensitiveData",
        label: "Exportar dados sensíveis",
        description: "Exportação de dados sensíveis como nome, e-mail e telefone.",
        items: [
          { key: "personalData", label: "Dados pessoais" },
          { key: "cemigInstallationNumber", label: "N° de instalação" },
          { key: "cemigCustomerNumber", label: "N° do cliente" },
        ],
      },
      {
        key: "readSensitiveData",
        label: "Visualizar dados sensíveis",
        description: "Visualização de dados sensíveis como nome, e-mail e telefone.",
        items: [
          { key: "personalData", label: "Dados pessoais" },
          { key: "cemigInstallationNumber", label: "N° de instalação" },
          { key: "cemigCustomerNumber", label: "N° do cliente" },
        ],
      },
      {
        key: "consumerUnits",
        label: "Unidades consumidoras",
        items: [
          { key: "read", label: "Visualizar" },
          { key: "create", label: "Incluir" },
          { key: "upgradeStatus", label: "Avançar status" },
          { key: "downgradeStatus", label: "Regredir status" },
          { key: "notifyStatus", label: "Notificar alteração de status" },
          {
            key: "update",
            label: "Alterar",
            items: [
              { key: "status", label: "Status" },
              { key: "substatus", label: "Substatus" },
              { key: "cemigInstallationNumber", label: "N° de instalação" },
              { key: "segment", label: "Segmento" },
              { key: "installationType", label: "Tipo de instalação" },
              { key: "customerClass", label: "Classe do cliente" },
              { key: "electricityBillAmount", label: "Valor da conta" },
              { key: "electricityAverageSpentInKwh", label: "Média do contrato" },
              { key: "checkedElectricityAverageSpentInKwh", label: "Média corrigida" },
              { key: "voltage", label: "Tensão da unidade" },
              { key: "address", label: "Endereço" },
              { key: "agreedDiscount", label: "Desconto acordado" },
              { key: "powerPlant", label: "Usina" },
              { key: "isChangeOwnershipNecessary", label: "Necessária troca de titularidade?" },
              { key: "isPortability", label: "Portabilidade?" },
              { key: "portabilityCompanyName", label: "Empresa de origem" },
              { key: "isUnifiedInvoice", label: "Modalidade de fatura" },
              { key: "distributorInvoiceReceiptMethod", label: "Recebimento fatura CEMIG" },
              { key: "cemigPaymentMethod", label: "Forma de pagamento atual na CEMIG" },
              { key: "invoiceReceiptMethod", label: "Como deseja receber a fatura?" },
              { key: "invoiceReceiptMethodJustify", label: "Explique como deseja receber a fatura" },
              { key: "loyaltyTimeInMonths", label: "Tempo de fidelidade" },
              { key: "invoiceDueDay", label: "Dia de vencimento da fatura" },
              { key: "approximateReadingDay", label: "Dia aprox. da leitura" },
              { key: "currentGenerationBalanceInKwh", label: "Saldo atual de geração" },
              { key: "ownGenerationInKwh", label: "Geração própria" },
              { key: "comments", label: "Observações" },
              { key: "tariffGroup", label: "Grupo tarifário" },
              { key: "expirationGroup", label: "Grupo de vencimento" },
              { key: "sendingGroup", label: "Grupo de envio" },
              { key: "promotion", label: "Promoção" },
              { key: "isBlockedCreateCharge", label: "Bloquear emissão de cobranças?" },
              { key: "createChargeBlockedUntilDate", label: "Desbloqueio automático em" },
              { key: "isLowIncome", label: "Baixa renda?" },
              { key: "isActive", label: "Ativo?" },
            ],
          },
          { key: "delete", label: "Excluir" },
          {
            key: "setIncompatibleElectricityAverageSpent",
            label: "Informar média de gastos incompatível com o tipo de instalação",
            description:
              "O usuário poderá realizar o cadastro de UCs com média de gastos inferiores aos valores estipulados para o tipo de instalação.",
          },
          {
            key: "updateAfterContractAdded",
            label: "Alterar após adição do contrato",
            description: "O usuário poderá realizar modificações na UC mesmo após a adição do contrato.",
          },
          { key: "readElectricityBalance", label: "Visualizar saldo de energia" },

          {
            key: "credits",
            label: "Créditos",
            items: [
              {
                key: "trader",
                label: "ClickLivre",
                items: [
                  { key: "read", label: "Visualizar" },
                  { key: "create", label: "Incluir" },
                  { key: "delete", label: "Excluir" },
                ],
              },
              {
                key: "distributor",
                label: "CEMIG",
                items: [
                  { key: "read", label: "Visualizar" },
                  { key: "create", label: "Incluir" },
                  { key: "delete", label: "Excluir" },
                ],
              },
            ],
          },
        ],
      },
      {
        key: "contracts",
        label: "Contratos",
        items: [
          { key: "read", label: "Visualizar" },
          { key: "create", label: "Incluir" },
          { key: "sendViaWhatsApp", label: "Enviar por WhatsApp" },
          { key: "cancel", label: "Cancelar" },
          { key: "delete", label: "Excluir" },
        ],
      },
      {
        key: "files",
        label: "Arquivos",
        items: [
          { key: "read", label: "Visualizar" },
          { key: "create", label: "Incluir" },
          { key: "update", label: "Alterar" },
          { key: "delete", label: "Excluir" },
        ],
      },
      {
        key: "legalRepresentatives",
        label: "Representantes legais",
        items: [
          { key: "read", label: "Visualizar" },
          { key: "create", label: "Incluir" },
          { key: "update", label: "Alterar" },
          { key: "delete", label: "Excluir" },
        ],
      },
      {
        key: "comments",
        label: "Observações",
        items: [
          { key: "read", label: "Visualizar" },
          { key: "create", label: "Incluir" },
          { key: "update", label: "Alterar" },
          { key: "delete", label: "Excluir" },
        ],
      },
      {
        key: "cemigFiles",
        label: "Arquivos CEMIG",
        items: [
          { key: "read", label: "Visualizar" },
          { key: "create", label: "Incluir" },
          { key: "update", label: "Alterar" },
          { key: "delete", label: "Excluir" },
        ],
      },
      {
        key: "indications",
        label: "Indicações",
        items: [{ key: "read", label: "Visualizar" }],
      },
    ],
  },

  {
    key: "leads",
    label: "Leads",
    items: [
      {
        key: "read",
        label: "Visualizar todos",
        description: "Caso o usuário não possua esta permissão ele poderá visualizar apenas os próprios cadastros.",
      },
      { key: "create", label: "Incluir" },
      {
        key: "update",
        label: "Alterar",
        items: [
          { key: "consultant", label: "Consultor" },
          { key: "status", label: "Status" },
          { key: "name", label: "Nome" },
          { key: "type", label: "Tipo de pessoa" },
          { key: "document", label: "Documento" },
          { key: "phone", label: "Telefone" },
          { key: "email", label: "E-mail" },
          { key: "consumerUnits", label: "Unidades consumidoras" },
          { key: "address", label: "Endereço" },
          { key: "indicatedBy", label: "Indicado por" },
          { key: "exhibition", label: "Evento" },
        ],
      },
      { key: "delete", label: "Excluir" },
      { key: "export", label: "Exportar" },
      {
        key: "exportSensitiveData",
        label: "Exportar dados sensíveis",
        description: "Exportação de dados sensíveis como nome, e-mail e telefone.",
        items: [
          { key: "cemigInstallationNumber", label: "N° de instalação" },
          { key: "personalData", label: "Dados pessoais" },
          { key: "addressData", label: "Endereço" },
        ],
      },
      {
        key: "readSensitiveData",
        label: "Visualizar dados sensíveis",
        description: "Visualização de dados sensíveis como nome, e-mail e telefone.",
        items: [
          { key: "cemigInstallationNumber", label: "N° de instalação" },
          { key: "personalData", label: "Dados pessoais" },
          { key: "addressData", label: "Endereço" },
        ],
      },
      {
        key: "files",
        label: "Arquivos",
        items: [
          { key: "read", label: "Visualizar" },
          { key: "create", label: "Incluir" },
          { key: "update", label: "Alterar" },
          { key: "delete", label: "Excluir" },
        ],
      },
    ],
  },

  {
    key: "users",
    label: "Usuários",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "create", label: "Incluir" },
      { key: "update", label: "Alterar" },
      { key: "delete", label: "Excluir" },
      { key: "export", label: "Exportar" },
    ],
  },

  {
    key: "expirationGroups",
    label: "Grupos de vencimento",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "create", label: "Incluir" },
      { key: "update", label: "Alterar" },
      { key: "delete", label: "Excluir" },
      { key: "export", label: "Exportar" },
    ],
  },

  {
    key: "sendingGroups",
    label: "Grupos de envio",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "create", label: "Incluir" },
      { key: "update", label: "Alterar" },
      { key: "delete", label: "Excluir" },
      { key: "export", label: "Exportar" },
    ],
  },

  {
    key: "powerPlants",
    label: "Usinas",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "create", label: "Incluir" },
      { key: "update", label: "Alterar" },
      { key: "delete", label: "Excluir" },
      { key: "export", label: "Exportar" },
    ],
  },

  {
    key: "powerPlantGenerations",
    label: "Gerações das usinas",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "create", label: "Incluir" },
      { key: "update", label: "Alterar" },
      { key: "delete", label: "Excluir" },
      { key: "export", label: "Exportar" },
    ],
  },

  {
    key: "exhibitions",
    label: "Eventos",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "create", label: "Incluir" },
      { key: "update", label: "Alterar" },
      { key: "delete", label: "Excluir" },
      { key: "export", label: "Exportar" },
    ],
  },

  {
    key: "segments",
    label: "Segmentos",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "create", label: "Incluir" },
      { key: "update", label: "Alterar" },
      { key: "delete", label: "Excluir" },
      { key: "export", label: "Exportar" },
    ],
  },

  {
    key: "invoiceInvalidationReasons",
    label: "Motivos de invalidação de faturas",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "create", label: "Incluir" },
      { key: "update", label: "Alterar" },
      { key: "delete", label: "Excluir" },
      { key: "export", label: "Exportar" },
    ],
  },

  {
    key: "statuses",
    label: "Status",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "create", label: "Incluir" },
      { key: "update", label: "Alterar" },
      { key: "delete", label: "Excluir" },
      { key: "export", label: "Exportar" },
    ],
  },

  {
    key: "promotions",
    label: "Promoções",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "create", label: "Incluir" },
      { key: "update", label: "Alterar" },
      { key: "delete", label: "Excluir" },
      { key: "export", label: "Exportar" },
    ],
  },

  {
    key: "tariffGroups",
    label: "Grupos tarifários",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "create", label: "Incluir" },
      { key: "update", label: "Alterar" },
      { key: "delete", label: "Excluir" },
      { key: "export", label: "Exportar" },
    ],
  },

  {
    key: "tariffBases",
    label: "Bases tarifárias",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "create", label: "Incluir" },
      { key: "update", label: "Alterar" },
      { key: "delete", label: "Excluir" },
      { key: "export", label: "Exportar" },
    ],
  },

  {
    key: "userGroups",
    label: "Grupos de usuário",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "create", label: "Incluir" },
      { key: "update", label: "Alterar" },
      { key: "delete", label: "Excluir" },
      { key: "export", label: "Exportar" },
    ],
  },

  {
    key: "faq",
    label: "Perguntas frequentes",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "create", label: "Incluir" },
      { key: "update", label: "Alterar" },
      { key: "delete", label: "Excluir" },
      { key: "export", label: "Exportar" },
    ],
  },

  {
    key: "invoices",
    label: "Faturas",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "readOnlySentToCollectionAgency", label: "Visualizar apenas os enviados para cobrança terceirizada" },
      { key: "create", label: "Incluir" },
      { key: "update", label: "Alterar" },
      { key: "validate", label: "Validar" },
      { key: "invalidate", label: "Invalidar" },
      { key: "sendToCollectionAgency", label: "Marcar/Desmarcar 'Cobrança Terceirizada', 'SPC', 'Protesto'" },
      { key: "delete", label: "Excluir" },
      { key: "export", label: "Exportar" },
      {
        key: "exportSensitiveData",
        label: "Exportar dados sensíveis",
        description: "Exportação de dados sensíveis como nome, e-mail e telefone.",
        items: [
          { key: "personalData", label: "Dados pessoais" },
          { key: "cemigInstallationNumber", label: "N° de instalação" },
        ],
      },
      {
        key: "readSensitiveData",
        label: "Visualizar dados sensíveis",
        description: "Visualização de dados sensíveis como nome, e-mail e telefone.",
        items: [
          { key: "personalData", label: "Dados pessoais" },
          { key: "cemigInstallationNumber", label: "N° de instalação" },
        ],
      },
    ],
  },

  {
    key: "invoiceCaptures",
    label: "Capturas de faturas",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "create", label: "Incluir" },
      { key: "abort", label: "Abortar" },
      { key: "export", label: "Exportar" },
    ],
  },

  {
    key: "charges",
    label: "Cobranças",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "readOnlySentToCollectionAgency", label: "Visualizar apenas os enviados para cobrança terceirizada" },
      { key: "create", label: "Incluir" },
      { key: "update", label: "Alterar" },
      { key: "cancel", label: "Cancelar" },
      { key: "export", label: "Exportar" },
      {
        key: "exportSensitiveData",
        label: "Exportar dados sensíveis",
        description: "Exportação de dados sensíveis como nome, e-mail e telefone.",
        items: [
          { key: "personalData", label: "Dados pessoais" },
          { key: "cemigInstallationNumber", label: "N° de instalação" },
        ],
      },
      {
        key: "readSensitiveData",
        label: "Visualizar dados sensíveis",
        description: "Visualização de dados sensíveis como nome, e-mail e telefone.",
        items: [
          { key: "personalData", label: "Dados pessoais" },
          { key: "cemigInstallationNumber", label: "N° de instalação" },
        ],
      },
    ],
  },

  {
    key: "payments",
    label: "Pagamentos",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "create", label: "Incluir" },
      { key: "update", label: "Alterar" },
      { key: "cancel", label: "Cancelar" },
      { key: "delete", label: "Excluir" },
      { key: "export", label: "Exportar" },
      {
        key: "exportSensitiveData",
        label: "Exportar dados sensíveis",
        description: "Exportação de dados sensíveis como nome, e-mail e telefone.",
        items: [
          { key: "personalData", label: "Dados pessoais" },
          { key: "cemigInstallationNumber", label: "N° de instalação" },
        ],
      },
      {
        key: "readSensitiveData",
        label: "Visualizar dados sensíveis",
        description: "Visualização de dados sensíveis como nome, e-mail e telefone.",
        items: [
          { key: "personalData", label: "Dados pessoais" },
          { key: "cemigInstallationNumber", label: "N° de instalação" },
        ],
      },
    ],
  },

  {
    key: "approvals",
    label: "Aprovações",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "update", label: "Alterar" },
      { key: "export", label: "Exportar" },
    ],
  },

  {
    key: "webhooks",
    label: "Webhooks",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "update", label: "Alterar" },
      { key: "export", label: "Exportar" },
    ],
  },

  {
    key: "powerManagement",
    label: "Gestão de energia",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "delete", label: "Excluir" },
      { key: "export", label: "Exportar" },
    ],
  },

  {
    key: "crons",
    label: "Crons",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "execute", label: "Executar" },
    ],
  },

  {
    key: "distributorMonitorings",
    label: "Monitoramentos CEMIG",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "update", label: "Alterar" },
      { key: "export", label: "Exportar" },
    ],
  },

  {
    key: "messages",
    label: "Mensagens",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "update", label: "Alterar" },
      { key: "export", label: "Exportar" },
    ],
  },

  {
    key: "alerts",
    label: "Alertas",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "update", label: "Alterar" },
      { key: "export", label: "Exportar" },
    ],
  },

  {
    key: "invoiceTemplates",
    label: "Templates de fatura",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "create", label: "Incluir" },
      { key: "update", label: "Alterar" },
      { key: "delete", label: "Excluir" },
      { key: "export", label: "Exportar" },
      { key: "addExtraPages", label: "Adicionar páginas extras" },
    ],
  },

  {
    key: "imports",
    label: "Importações",
    items: [
      { key: "consumerUnitsStatuses", label: "Status das UCs" },
      { key: "consumerUnitsPowerPlants", label: "Usinas das UCs" },
      { key: "consumerUnitsQuotas", label: "Quotas das UCs" },
    ],
  },
];

const recursive = (parentKeys, item) => {
  if (item.items) {
    const mapped = item.items.map((o) => {
      o.values = recursive([...parentKeys, o.key], o);
      return o.values;
    });
    return _.flattenDeep(mapped);
  }
  return [parentKeys.join(".")];
};

for (const item of groups) item.values = recursive([item.key], item);

export default _.sortBy(groups, [(o) => o.label]);
