export const alertTopics = {
  CONSUMER_UNIT_NOT_FOUND: "UC não cadastrada",
  CONSUMER_UNIT_WITHOUT_TARIFF_GROUP: "UC sem Grupo Tarifário",
  CONSUMER_UNIT_UPDATED_TO_SPLIT_INVOICE: "UC modificada para fatura separada",
  CONSUMER_UNIT_CREATE_CHARGE_BLOCKED: "UC bloqueada para emissão de cobranças",
  INVOICE_NOT_LOW_VOLTAGE: "Fatura média tensão",
  INVOICE_PREVIOUS_REFERENCE_NOT_FOUND: "Fatura não encontrada",
  INVOICE_LOW_INCOME_CREATED_MANUALLY: "Fatura baixa renda ignorada",
  INVOICE_LOW_INCOME_BLOCKED: "Fatura baixa renda temporariamente bloqueada",
  INVOICE_TARIFF_MODALITY_WHITE: "Fatura tarifa branca",
  INVOICE_PROTESTED_PAID: "Fatura protestada paga",
  INVOICE_PROCESSING_TIMEOUT: "Fatura processando",
  INVOICE_DISTRIBUTOR_LOW_AMOUNT: "Fatura abaixo de R$ 10,00",
  DISTRIBUTOR_CUSTOMER_NUMBER_CHANGED: "Número do cliente alterado",
};
