import moment from "moment";
import { Text, Link } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { currency, locale, masks, percent, translator } from "lib";
import { StatusBadge } from "components";

const defaultColumns = [
  {
    accessor: "nid",
    title: "NID",
    isVisible: true,
    formatter: ({ nid }) => nid,
  },
  {
    accessor: "status",
    title: "Status",
    isVisible: true,
    render: ({ status }) => <StatusBadge schema="invoices" status={status} />,
    exporter: ({ status }) => translator(status),
  },
  {
    accessor: "referenceCode",
    title: "Cód. referência",
    isVisible: true,
    render: ({ _id, referenceCode }) => (
      <Link as={RouterLink} to={`details/${_id}`} target="_blank">
        {referenceCode}
      </Link>
    ),
    exporter: ({ referenceCode }) => referenceCode,
  },
  {
    accessor: "customer.name",
    title: "Nome/Cliente",
    isVisible: true,
    isSortable: false,
    render: ({ customer }) => customer?.name || "-",
    exporter: ({ customer }, { isAllowedExportSensitiveData }) => {
      if (isAllowedExportSensitiveData.personalData) return customer?.name || "-";
      return "****";
    },
  },
  {
    accessor: "customer.document",
    title: "Documento/Cliente",
    isVisible: true,
    isSortable: false,
    render: ({ customer }, { isAllowedReadSensitiveData }) => {
      if (isAllowedReadSensitiveData.personalData) return masks.document(customer?.document || "-");
      return "****";
    },
    exporter: ({ customer }, { isAllowedExportSensitiveData }) => {
      if (isAllowedExportSensitiveData.personalData) return `'${customer?.document || "-"}`;
      return "****";
    },
  },
  {
    accessor: "customer.type",
    title: "Tipo/Cliente",
    isVisible: false,
    isSortable: false,
    formatter: ({ customer }) => customer?.type?.toUpperCase() || "-",
  },
  {
    accessor: "consumerUnit.cemigInstallationNumber",
    title: "N° instalação",
    isVisible: true,
    formatter: ({ consumerUnit }) => consumerUnit?.cemigInstallationNumber || "-",
  },
  {
    accessor: "distributor.current.class",
    title: "Classe",
    isVisible: true,
    formatter: ({ distributor }) => distributor?.current?.class || "-",
  },
  {
    accessor: "distributor.current.subclass",
    title: "Subclasse",
    isVisible: true,
    formatter: ({ distributor }) => distributor?.current?.subclass || "-",
  },
  {
    accessor: "tariffGroup.title",
    title: "Grupo tarifário",
    isVisible: true,
    formatter: ({ tariffGroup }) => tariffGroup?.title || "-",
  },
  {
    accessor: "promotionDiscount.promotion.title",
    title: "Promoção",
    isVisible: true,
    isSortable: false,
    formatter: ({ promotionDiscount }) => promotionDiscount?.promotion?.title || "-",
  },
  {
    accessor: "promotionDiscount.durationInMonths",
    title: "Dur. promoção",
    isVisible: true,
    isSortable: false,
    formatter: ({ promotionDiscount }) => promotionDiscount?.durationInMonths || "-",
  },
  {
    accessor: "promotionDiscount.usageInMonths",
    title: "Mês atual promoção",
    isVisible: true,
    formatter: ({ promotionDiscount }) => promotionDiscount?.usageInMonths || "-",
  },
  {
    accessor: "agreedDiscount.percentage",
    title: "Desc. acordado",
    isVisible: true,
    formatter: ({ agreedDiscount }) => percent(agreedDiscount.percentage),
  },
  {
    accessor: "promotionDiscount.percentage",
    title: "Desc. promoção",
    isVisible: true,
    formatter: ({ promotionDiscount }) => percent(promotionDiscount?.percentage),
  },
  {
    accessor: "discountPercentage",
    title: "Desc. aplicado",
    isVisible: true,
    formatter: ({ discountPercentage }) => percent(discountPercentage),
  },
  {
    accessor: "distributorCreditAmount",
    title: "Créd. CEMIG",
    isVisible: true,
    formatter: ({ distributorCreditAmount }) => currency(distributorCreditAmount),
  },
  {
    accessor: "traderCreditAmount",
    title: "Créd. Click",
    isVisible: true,
    formatter: ({ traderCreditAmount }) => currency(traderCreditAmount),
  },
  {
    accessor: "referenceDate",
    title: "Dt. referência",
    isVisible: true,
    formatter: ({ referenceDate }) => moment(referenceDate).format("MM/YYYY"),
  },
  {
    accessor: "issueDate",
    title: "Dt. emissão",
    isVisible: true,
    formatter: ({ issueDate }) => moment(issueDate).format("DD/MM/YYYY"),
  },
  {
    accessor: "dueDate",
    title: "Dt. vencimento",
    isVisible: true,
    formatter: ({ dueDate }) => moment(dueDate).format("DD/MM/YYYY"),
  },
  {
    accessor: "distributor.current.keyValueItems.eletric_power.powerQttInKwh",
    title: "Energia CEMIG",
    isVisible: true,
    render: ({ distributor }) => <Text>{distributor?.current.keyValueItems?.eletric_power?.powerQttInKwh?.toLocaleString() || 0} kWh</Text>,
    exporter: ({ distributor }) => distributor?.current.keyValueItems?.eletric_power?.powerQttInKwh?.toLocaleString() || 0,
  },
  {
    accessor: "distributor.current.keyValueItems.eletric_power.powerPricePerKwh",
    title: "Tarifa CEMIG",
    isVisible: true,
    render: ({ distributor }) => currency(distributor?.current.keyValueItems?.eletric_power?.powerPricePerKwh, { precision: 8 }),
    exporter: ({ distributor }) =>
      currency(distributor?.current.keyValueItems?.eletric_power?.powerPricePerKwh, { precision: 8, compact: true }),
  },
  {
    accessor: "distributor.current.keyValueItems.scee.powerQttInKwh",
    title: "Energia SCEE",
    isVisible: true,
    render: ({ distributor }) => <Text>{distributor?.current.keyValueItems?.scee?.powerQttInKwh?.toLocaleString() || 0} kWh</Text>,
    exporter: ({ distributor }) => distributor?.current.keyValueItems?.scee?.powerQttInKwh?.toLocaleString() || 0,
  },
  {
    accessor: "distributor.current.keyValueItems.scee.powerPricePerKwh",
    title: "Tarifa SCEE",
    isVisible: true,
    render: ({ distributor }) => currency(distributor?.current.keyValueItems?.scee?.powerPricePerKwh, { precision: 8 }),
    exporter: ({ distributor }) => currency(distributor?.current.keyValueItems?.scee?.powerPricePerKwh, { precision: 8, compact: true }),
  },
  {
    accessor: "distributor.current.keyValueItems.compensated_gd.powerQttInKwh",
    title: "Energia Compensada GDI",
    isVisible: true,
    render: ({ distributor }) => (
      <Text>{distributor?.current.keyValueItems?.compensated_gd?.powerQttInKwh?.toLocaleString() || 0} kWh</Text>
    ),
    exporter: ({ distributor }) => distributor?.current.keyValueItems?.compensated_gd?.powerQttInKwh?.toLocaleString() || 0,
  },
  {
    accessor: "distributor.current.keyValueItems.compensated_gd.powerPricePerKwh",
    title: "Tarifa Compensada GDI",
    isVisible: true,
    render: ({ distributor }) => currency(distributor?.current.keyValueItems?.compensated_gd?.powerPricePerKwh, { precision: 8 }),
    exporter: ({ distributor }) =>
      currency(distributor?.current.keyValueItems?.compensated_gd?.powerPricePerKwh, { precision: 8, compact: true }),
  },
  {
    accessor: "distributor.current.keyValueItems.scee.pisCofinsAmount",
    title: "PIS/COFINS SCEE",
    isVisible: true,
    render: ({ distributor }) => currency(distributor?.current.keyValueItems?.scee?.pisCofinsAmount),
    exporter: ({ distributor }) => currency(distributor?.current.keyValueItems?.scee?.pisCofinsAmount, { compact: true }),
  },
  {
    accessor: "keyValueItems.eletric_power.powerQttInKwh",
    title: "Energia Click",
    isVisible: true,
    render: ({ keyValueItems }) => <Text>{keyValueItems?.eletric_power?.powerQttInKwh?.toLocaleString() || 0} kWh</Text>,
    exporter: ({ keyValueItems }) => keyValueItems?.eletric_power?.powerQttInKwh?.toLocaleString() || 0,
  },
  {
    accessor: "keyValueItems.eletric_power.powerPricePerKwh",
    title: "Tarifa Click",
    isVisible: true,
    render: ({ keyValueItems }) => currency(keyValueItems?.eletric_power?.powerPricePerKwh, { precision: 8 }),
    exporter: ({ keyValueItems }) => currency(keyValueItems?.eletric_power?.powerPricePerKwh, { precision: 8, compact: true }),
  },
  {
    accessor: "distributor.current.amount",
    title: "Valor CEMIG",
    isVisible: true,
    render: ({ distributor }) => currency(distributor?.current.amount),
    exporter: ({ distributor }) => currency(distributor?.current.amount, { compact: true }),
    renderFoot: ({ distributorAmount }) => currency(distributorAmount),
  },
  {
    accessor: "promotionDiscount.amount",
    title: "Energia Click c/ promoção",
    isVisible: true,
    render: ({ promotionDiscount }) => currency(promotionDiscount?.amount),
    exporter: ({ promotionDiscount }) => currency(promotionDiscount?.amount, { compact: true }),
    renderFoot: ({ promotionDiscountAmount }) => currency(promotionDiscountAmount),
  },
  {
    accessor: "agreedDiscount.amount",
    title: "Energia Click s/ promoção",
    isVisible: true,
    render: ({ agreedDiscount }) => currency(agreedDiscount.amount),
    exporter: ({ agreedDiscount }) => currency(agreedDiscount.amount, { compact: true }),
    renderFoot: ({ agreedDiscountAmount }) => currency(agreedDiscountAmount),
  },
  {
    accessor: "amount",
    title: "Valor total Click",
    isVisible: true,
    render: ({ amount }) => currency(amount),
    exporter: ({ amount }) => currency(amount, { compact: true }),
    renderFoot: ({ amount }) => currency(amount),
  },
  {
    accessor: "receivableAmount",
    title: "Valor total fatura",
    isVisible: true,
    render: ({ receivableAmount }) => currency(receivableAmount),
    exporter: ({ receivableAmount }) => currency(receivableAmount, { compact: true }),
    renderFoot: ({ receivableAmount }) => currency(receivableAmount),
  },
  {
    accessor: "sendingMethod",
    title: "Mét. receb. fatura",
    isVisible: true,
    formatter: ({ sendingMethod }) => translator(sendingMethod),
  },
  {
    accessor: "messagedAt",
    title: "Dt. último envio",
    isVisible: true,
    isSortable: false,
    formatter: ({ messagedAt }) => (messagedAt ? moment(messagedAt).format("DD/MM/YYYY [às] HH:mm") : "-"),
  },
  {
    accessor: "isDirectDebit",
    title: "Débito automático?",
    isVisible: true,
    formatter: ({ isDirectDebit }) => (isDirectDebit ? "Sim" : "Não"),
  },
  {
    accessor: "distributor.current.isLowIncome",
    title: "Baixa renda?",
    isVisible: true,
    formatter: ({ distributor }) => (distributor?.current.isLowIncome ? "Sim" : "Não"),
  },
  {
    accessor: "isFirstInvoiceIssue",
    title: "Primeira fatura?",
    isVisible: true,
    formatter: ({ isFirstInvoiceIssue }) => (isFirstInvoiceIssue ? "Sim" : "Não"),
  },
  {
    accessor: "isFirstBankSlipIssue",
    title: "Primeiro boleto?",
    isVisible: true,
    formatter: ({ isFirstBankSlipIssue }) => (isFirstBankSlipIssue ? "Sim" : "Não"),
  },
  {
    accessor: "powerPlant.name",
    title: "Usina",
    isVisible: false,
    isSortable: false,
    formatter: ({ powerPlant }) => powerPlant?.name || "-",
  },
  {
    accessor: "charge.paidAt",
    title: "Pago em",
    isVisible: true,
    formatter: ({ charge }) => (charge?.paidAt ? moment(charge.paidAt).format("DD/MM/YYYY") : "-"),
  },
  {
    accessor: "finishedAt",
    title: "Finalizado em",
    isVisible: true,
    formatter: ({ finishedAt }) => (finishedAt ? moment(finishedAt).format("DD/MM/YYYY [às] HH:mm") : "-"),
  },
  {
    accessor: "distributor.current.onPeakBalanceInKwh",
    title: "Saldo PONTA",
    isVisible: false,
    formatter: ({ distributor }) => locale(distributor?.current?.onPeakBalanceInKwh, { precision: 0 }).concat(" kWh"),
  },
  {
    accessor: "distributor.current.offPeakBalanceInKwh",
    title: "Saldo FORAP",
    isVisible: false,
    formatter: ({ distributor }) => locale(distributor?.current?.offPeakBalanceInKwh, { precision: 0 }).concat(" kWh"),
  },
  {
    accessor: "distributor.current.totalBalanceInKwh",
    title: "Saldo TOTAL",
    isVisible: false,
    formatter: ({ distributor }) => locale(distributor?.current?.totalBalanceInKwh, { precision: 0 }).concat(" kWh"),
  },
  {
    accessor: "penaltyAmount",
    title: "Multa",
    isVisible: false,
    formatter: ({ penaltyAmount }) => currency(penaltyAmount),
  },
  {
    accessor: "arrearsAmount",
    title: "R$ Juros",
    isVisible: false,
    formatter: ({ arrearsAmount }) => currency(arrearsAmount),
  },
  {
    accessor: "arrearsPercentage",
    title: "% Juros",
    isVisible: false,
    formatter: ({ arrearsPercentage }) => percent(arrearsPercentage),
  },
  {
    accessor: "monetaryCorrectionAmount",
    title: "R$ Correção IPCA",
    isVisible: false,
    formatter: ({ monetaryCorrectionAmount }) => currency(monetaryCorrectionAmount),
  },
  {
    accessor: "monetaryCorrectionPercentage",
    title: "% Correção IPCA",
    isVisible: false,
    formatter: ({ monetaryCorrectionPercentage }) => percent(monetaryCorrectionPercentage),
  },
  {
    accessor: "isProtested",
    title: "Protestada?",
    isVisible: true,
    formatter: ({ isProtested }) => (isProtested ? "Sim" : "Não"),
  },
  {
    accessor: "isSpcClean",
    title: "SPC Limpo?",
    isVisible: true,
    formatter: ({ isSpcClean }) => (isSpcClean ? "Sim" : "Não"),
  },
  {
    accessor: "isSpcDirty",
    title: "SPC Sujo?",
    isVisible: true,
    formatter: ({ isSpcDirty }) => (isSpcDirty ? "Sim" : "Não"),
  },
  {
    accessor: "isUnified",
    title: "Modalidade de fatura",
    isVisible: true,
    formatter: ({ isUnified }) => (isUnified ? "Unificada" : "Separada"),
  },
  {
    accessor: "currentCount",
    title: "Número atual",
    isVisible: true,
    formatter: ({ currentCount }) => currentCount,
  },
  {
    accessor: "consumerUnit.checkedElectricityAverageSpentInKwh",
    title: "Média corrigida (kWh)",
    isVisible: true,
    formatter: ({ consumerUnit }) => locale(consumerUnit?.checkedElectricityAverageSpentInKwh, { precision: 0 }),
  },
  {
    accessor: "consumerUnit.netElectricityAverageSpentInKwh",
    title: "Média líquida (kWh)",
    isVisible: true,
    formatter: ({ consumerUnit }) => locale(consumerUnit?.netElectricityAverageSpentInKwh, { precision: 0 }),
  },
  {
    accessor: "consumerUnit.address.city",
    title: "Cidade",
    isVisible: true,
    formatter: ({ consumerUnit }) => consumerUnit?.address?.city || "-",
  },
  {
    accessor: "consumerUnit.isChangeOwnershipNecessary",
    title: "TT?",
    isVisible: true,
    formatter: ({ consumerUnit }) => (consumerUnit?.isChangeOwnershipNecessary ? "Sim" : "Não"),
  },
  {
    accessor: "consumerUnit.isPortability",
    title: "Port.?",
    isVisible: true,
    formatter: ({ consumerUnit }) => (consumerUnit?.isPortability ? "Sim" : "Não"),
  },
  {
    accessor: "charge.amountPaid",
    title: "Valor pago",
    isVisible: true,
    formatter: ({ charge }) => currency(charge?.amountPaid || 0),
  },
  {
    accessor: "charge.dueDate",
    title: "Dt. venc. cobrança",
    isVisible: true,
    formatter: ({ charge }) => (charge?.dueDate ? moment(charge.dueDate).format("DD/MM/YYYY") : "-"),
  },
  {
    accessor: "charge.paymentMethod",
    title: "Mét. pagamento",
    isVisible: true,
    formatter: ({ charge }) => charge?.paymentMethod ?? "-",
  },
  {
    accessor: "payment",
    title: "Possui pagamento?",
    isVisible: true,
    formatter: ({ payment }) => (payment ? "Sim" : "Não"),
  },
  {
    accessor: "consumerUnit.substatus",
    title: "Substatus/UC",
    isVisible: true,
    isSortable: false,
    render: ({ consumerUnit }) => <StatusBadge {...consumerUnit?.substatus} />,
    exporter: ({ consumerUnit }) => consumerUnit?.substatus?.title,
  },
  {
    accessor: "customer.consultant.name",
    title: "Consultor",
    isVisible: true,
    isSortable: false,
    formatter: ({ customer }) => customer?.consultant?.name || "-",
  },
  {
    accessor: "createdBy",
    title: "Criado por",
    isVisible: true,
    formatter: ({ createdBy }) => createdBy?.name,
  },
  {
    accessor: "createdAt",
    title: "Criado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY [às] HH:mm"),
  },
];

export default defaultColumns;
