import React, { Fragment, useContext, useEffect, useMemo } from "react";
import _ from "lodash";
import moment from "moment";
import { useParams } from "react-router-dom";
import {
  Box,
  Center,
  HStack,
  Icon,
  IconButton,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  VStack,
  StackDivider,
} from "@chakra-ui/react";
import { BankSlipDownload, BankSlipSend, ChargeCancel, ChargeUpdate } from "containers";
import { PermissionedContainer, StatusBadge } from "components";
import { useQueryParams, useClipboard, useFetchData } from "hooks";
import { EventEmitter, currency, percent } from "lib";
import { TbPigMoney } from "react-icons/tb";
import { MdMoreHoriz, MdOutlineQrCodeScanner, MdRefresh } from "react-icons/md";
import { FiClipboard } from "react-icons/fi";
import { BiBarcodeReader, BiListUl } from "react-icons/bi";
import InvoicesDetailsContext from "../context";

const Charges = () => {
  const { _id } = useParams();
  const { formData, setCounters, setIsLoading, refreshData } = useContext(InvoicesDetailsContext);
  const { setQueryParams } = useQueryParams();
  const copyToClipboard = useClipboard();
  const [charges, isLoadingCharges, refreshCharges] = useFetchData(
    useMemo(
      () => ({
        path: "/private/charges",
        params: { query: { invoice: { _id: ["@ObjectId", _id] } }, sort: { createdAt: -1 } },
        options: { isEnabled: _.isString(_id) },
      }),
      [_id]
    )
  );

  useEffect(() => {
    setCounters((state) => ({ ...state, charges: _.size(charges?.data) }));
  }, [charges?.data]);

  useEffect(() => {
    setIsLoading((state) => ({ ...state, charges: isLoadingCharges }));
  }, [isLoadingCharges]);

  useEffect(() => {
    const listener = EventEmitter.addListener("charges.refresh", () => {
      refreshData();
      refreshCharges();
    });
    return () => listener.remove();
  }, [refreshData, refreshCharges]);

  return (
    <>
      <HStack mb="20px">
        <Text flex="1" fontSize="sm" fontWeight="semibold">
          {_.size(charges?.data)} registros
        </Text>
        <IconButton size="sm" variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingCharges} onClick={refreshCharges} />
      </HStack>

      <Table size="sm" whiteSpace="nowrap">
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>Status</Th>
            <Th>N° Documento</Th>
            <Th>Nosso número</Th>
            <Th>Pagador</Th>
            <Th>Vencimento</Th>
            <Th>Data do último evento</Th>
            <Th>Pago em</Th>
            <Th>Método pgto</Th>
            <Th>Valor</Th>
            <Th>Valor pago</Th>
            <Th>Multa</Th>
            <Th>Juros</Th>
            <Th>Correção IPCA</Th>
            <Th>Criada por</Th>
            <Th>Criada em</Th>
          </Tr>
        </Thead>
        <Tbody>
          {_.map(charges?.data, (item) => {
            const isPending = _.includes(["created", "updated"], item.status);
            const isActive = _.includes(["created", "updated", "paid"], item.status);
            return (
              <Tr
                key={item._id}
                cursor="pointer"
                _hover={{ _light: { bg: "gray.50" }, _dark: { bg: "gray.700" } }}
                _active={{ _light: { bg: "gray.100" }, _dark: { bg: "gray.900" } }}
                onDoubleClick={() => setQueryParams((params) => ({ ...params, charge_id: item._id }), { replace: true })}
              >
                <Td>
                  <Menu placement="right-start">
                    <MenuButton as={IconButton} size="xs" colorScheme="main" icon={<Icon as={MdMoreHoriz} />} />
                    <MenuList>
                      <MenuGroup title={item.referenceNumber} pb="5px">
                        <VStack spacing={2} divider={<StackDivider />}>
                          <Fragment>
                            <MenuItem icon={<Icon as={FiClipboard} />} onClick={() => copyToClipboard(item._id)}>
                              copiar código
                            </MenuItem>
                            <PermissionedContainer required="charges.read">
                              <MenuItem
                                icon={<Icon as={BiListUl} />}
                                onClick={() => setQueryParams((params) => ({ ...params, charge_id: item._id }), { replace: true })}
                              >
                                detalhes
                              </MenuItem>
                            </PermissionedContainer>
                          </Fragment>
                          {isActive && (
                            <Fragment>
                              <BankSlipDownload invoice={formData} />
                              <BankSlipSend appearance="MenuItem" invoice={formData} charge={item} customer={formData.customer} />
                            </Fragment>
                          )}
                          {isPending && (
                            <Fragment>
                              <MenuItem onClick={() => copyToClipboard(item.digitableLine)}>
                                <HStack>
                                  <Icon as={BiBarcodeReader} />
                                  <Text>copiar linha digitável</Text>
                                </HStack>
                              </MenuItem>
                              <MenuItem onClick={() => copyToClipboard(item.pixInfo.emv)}>
                                <HStack>
                                  <Icon as={MdOutlineQrCodeScanner} />
                                  <Text>copiar QrCode PIX</Text>
                                </HStack>
                              </MenuItem>
                            </Fragment>
                          )}
                          {isPending && <ChargeUpdate charge={{ ...item, invoice: formData }} />}
                          {isPending && <ChargeCancel charge={item} />}
                        </VStack>
                      </MenuGroup>
                    </MenuList>
                  </Menu>
                </Td>
                <Td>
                  <StatusBadge schema="charges" status={item.status} />
                </Td>
                <Td>{item.referenceNumber}</Td>
                <Td>{item.ourNumber}</Td>
                <Td>{item.customer?.name}</Td>
                <Td>{moment(item.dueDate).format("DD/MM/YYYY")}</Td>
                <Td>{moment(item.updatedAt).format("DD/MM/YYYY [às] HH:mm")}</Td>
                <Td>{item.paidAt ? moment(item.paidAt).format("DD/MM/YYYY") : "-"}</Td>
                <Td>{item.paymentMethod}</Td>
                <Td>{currency(item.amount)}</Td>
                <Td>{currency(item.amountPaid)}</Td>
                <Td>{currency(item.penaltyAmount)}</Td>
                <Td>
                  {currency(item.arrearsAmount)} ({percent(item.arrearsPercentage)})
                </Td>
                <Td>
                  {currency(item.monetaryCorrectionAmount)} ({percent(item.monetaryCorrectionPercentage)})
                </Td>
                <Td>{item.createdBy.name}</Td>
                <Td>{moment(item.createdAt).format("DD/MM/YYYY")}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>

      {isLoadingCharges ? (
        <Center p="40px">
          <Spinner />
        </Center>
      ) : (
        _.size(charges?.data) === 0 && (
          <Center paddingTop="40px" paddingBottom="20px">
            <Box textAlign="center">
              <Icon as={TbPigMoney} boxSize={20} marginBottom="10px" />
              <Text fontSize="lg" fontWeight="bold">
                Nenhuma cobrança adicionada
              </Text>
              <Text fontSize="sm">Esta fatura ainda não possui cobranças adicionadas.</Text>
            </Box>
          </Center>
        )
      )}
    </>
  );
};

export default Charges;
